async function importFlickity() {
    const { default: Flickity } = await import(/* webpackChunkName: "flickity" */ 'flickity');
    return Flickity;
}

importFlickity().then((Flickity) => {
    const homeTestimonialSlider = document.querySelector('.home-testimonials');
    new Flickity(homeTestimonialSlider, {
        adaptiveHeight: true,
        draggable: '>1',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false
    });
});